import React, { useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo/Untitled-1-01.png";
import { Squash as Hamburger } from "hamburger-react";
import "./styles.css";
function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const scrollToTop = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <React.Fragment>
      <div className="header-container">
        <Container maxWidth="xl">
          <div className="header-content">
            <div className="logo">
              <span style={{ cursor: "pointer" }} onClick={scrollToTop}>
                <img src={logo} alt="logo" />
              </span>
            </div>
            <div className="list-items">
              <ul className={menuOpen ? "menu-open" : "menu-not-open"}>
                <li onClick={scrollToTop}>Home</li>
                <li onClick={() => navigate("/about")}>About</li>
                <li onClick={() => navigate("/portfolio")}>Portfolio</li>
                <li onClick={() => navigate("/testimonials")}>Testimonials</li>
                <li onClick={() => navigate("/contact-us")}>
                  <button className="contact-button">Contact</button>
                </li>
              </ul>
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
              <div className="menu-bars" animate={menuOpen ? "open" : "closed"}>
                <Hamburger />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Header;
