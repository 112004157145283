import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";
import { Container } from "@mui/material";
import topImage from "../../assets/landingPage/Services-2.png";
import "./styles.css";
import kitchenfirst from "../../assets/kitchen/cozy-modern-kitchen-white-room-interior.jpg";
import kitchensecond from "../../assets/kitchen/beautiful-shot-modern-house-kitchen.jpg";
import kitchenthird from "../../assets/kitchen/3d-rendering-white-minimal-kitchen-with-wood-decoration.webp";
import kitchenfourth from "../../assets/kitchen/empty-modern-room-with-furniture.jpg";
import livingroomfirst from "../../assets/living-area/gray-sofa-brown-living-room-with-copy-space.jpg";
import livingroomsecond from "../../assets/living-area/interior-design-with-photoframes-couch.jpg";
import livingroomthird from "../../assets/living-area/modern-luxury-interior-living-room-is-bright-clean-3d-illustration.jpg";
import livingroomfourth from "../../assets/living-area/spacious-living-room.jpg";
import officespacefirst from "../../assets/office-space/3d-render-modern-home-office.jpg";
import officespacesecond from "../../assets/office-space/front-view-off-office-desk.jpg";
import officespacethird from "../../assets/office-space/interior-design-with-photoframes-desk-arrangement.jpg";
import officespacefourth from "../../assets/office-space/view-modern-office.jpg";
import bedroomfirst from "../../assets/bedroom/3d-rendering-beautiful-comtemporary-luxury-bedroom-suite-hotel-with-tv.jpg";
import bedroomsecond from "../../assets/bedroom/beautiful-luxury-bedroom-suite-hotel.jpg";
import bedroomthird from "../../assets/bedroom/illustration-poster-frame-interior-background.jpg";
import bedroomfourth from "../../assets/bedroom/modern-light-bedroom-with-wooden-furniture-scandinavian-style-3d-rendering.jpg";
import { Helmet } from "react-helmet-async";
function OurServices() {
  return (
    <>
      <Helmet>
        <title>S V Interior - Portfolio</title>
        <meta
          name="description"
          content="The heart of your home, reimagined with our beautiful design."
        />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div className="services-main-container">
        <img className="services-main-container-img" src={topImage} alt="" />
        <div className="services-overlay">
          <Container maxWidth="xl">
            <section className="services-text-content">
              <div className="services-title">Project Sites</div>
              <br />
              <br />
              <div className="services-sub-title">
                Your All-in-One Interior Design Solution.
              </div>
            </section>
          </Container>
        </div>
      </div>
      <div className="all-services-list">
        <Container maxWidth="xl">
          <div className="services-header">Kitchen</div>
          <div className="services-top">
            <div className="services-top-left">
              <img src={kitchenfirst} alt="" />
            </div>
            <div className="services-top-right">
              <div className="services-topr-right-title">Kitchen</div>
              <br />
              <div className="services-topr-right-sub-title">
                The heart of your home,
              </div>
              <div className="services-topr-right-sub-title">
                reimagined with our beautiful design.
              </div>
            </div>
          </div>
          <div className="services-bottom">
            <div>
              <img src={kitchensecond} alt="" />
            </div>
            <div>
              <img src={kitchenthird} alt="" />
            </div>
            <div>
              <img src={kitchenfourth} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className="all-services-list">
        <Container maxWidth="xl">
          <div className="services-header">Living Room</div>
          <div className="services-top">
            <div className="services-top-left">
              <img src={livingroomfirst} alt="" />
            </div>
            <div className="services-top-right">
              <div className="services-topr-right-title">Living Room</div>
              <br />
              <div className="services-topr-right-sub-title">
                The heart of your home,
              </div>
              <div className="services-topr-right-sub-title">
                reimagined with our beautiful design.
              </div>
            </div>
          </div>
          <div className="services-bottom">
            <div>
              <img src={livingroomsecond} alt="" />
            </div>
            <div>
              <img src={livingroomthird} alt="" />
            </div>
            <div>
              <img src={livingroomfourth} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className="all-services-list">
        <Container maxWidth="xl">
          <div className="services-header">Office Space</div>
          <div className="services-top">
            <div className="services-top-left">
              <img src={officespacefirst} alt="" />
            </div>
            <div className="services-top-right">
              <div className="services-topr-right-title">Office Space</div>
              <br />
              <div className="services-topr-right-sub-title">
                The heart of your home,
              </div>
              <div className="services-topr-right-sub-title">
                reimagined with our beautiful design.
              </div>
            </div>
          </div>
          <div className="services-bottom">
            <div>
              <img src={officespacesecond} alt="" />
            </div>
            <div>
              <img src={officespacethird} alt="" />
            </div>
            <div>
              <img src={officespacefourth} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className="all-services-list">
        <Container maxWidth="xl">
          <div className="services-header">Bedroom</div>
          <div className="services-top">
            <div className="services-top-left">
              <img src={bedroomfirst} alt="" />
            </div>
            <div className="services-top-right">
              <div className="services-topr-right-title">Bedroom</div>
              <br />
              <div className="services-topr-right-sub-title">
                The heart of your home,
              </div>
              <div className="services-topr-right-sub-title">
                reimagined with our beautiful design.
              </div>
            </div>
          </div>
          <div className="services-bottom">
            <div>
              <img src={bedroomsecond} alt="" />
            </div>
            <div>
              <img src={bedroomthird} alt="" />
            </div>
            <div>
              <img src={bedroomfourth} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default OurServices;
