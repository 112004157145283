import React, { useEffect, Suspense } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import OurServices from "./Pages/services/ourServices";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";

const HomePage = React.lazy(() => import("./Pages/HomePage/HomePage"));
const NotFound = React.lazy(() => import("./Pages/notFound/notFound"));
const ContactUs = React.lazy(() => import("./Pages/ContactUs/contactUs"));
const AboutUs = React.lazy(() => import("./Pages/AboutUs/aboutUs"));
const Factory = React.lazy(() => import("./Pages/Factory/factory"));
const Testimonials = React.lazy(() =>
  import("./Pages/testimonials/testimonials")
);

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 700,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div className="loading-spinner">
              <PulseLoader
                color="#007bff"
                loading={true}
                size={25}
                margin={2}
              />
              S V Interior....
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/portfolio" element={<OurServices />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/factory-and-machinery" element={<Factory />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
